import { createApi } from '@reduxjs/toolkit/query/react'
import { GetProjectCompanyListQuery } from './company/GetProjectCompanyListQuery'
import { DeleteProjectCompanyQuery } from './company/DeleteProjectCompanyQuery'
import { AddCompaniesToProjectQuery } from './AddCompaniesToProjectQuery'
import { GetInvitationsInProjectsListQuery, IProjectInviteResponse } from './GetInvitationsInProjectsListQuery'
import { SendAnswerForInvitationInProjectQuery } from './SendAnswerForInvitationInProjectQuery'
import { GetProjectByIdQuery } from './GetProjectByIdQuery'
import { PutProjectByIdQuey } from './PutProjectByIdQuery'
import { PutAddAndRemoveUsersInProject } from './PutAddAndRemoveUsersInProject'
import { GetProjectPositionListQuery } from './position/GetProjectPositionListQuery'
import { DeleteProjectByIdQuery } from './DeleteProjectByIdQuery'
import { addedNewNotification } from '../../redux/notifications/actions'
import { baseQuery } from '../BaseQuery'


export const projectApi = createApi({
  reducerPath: 'projectApi',
  tagTypes: ['project', 'company-project', 'invites-list'],
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getProjectPositionsList: builder.query({
      ...new GetProjectPositionListQuery()
    }),
    getProjectCompaniesList: builder.query({
      ...new GetProjectCompanyListQuery(),
    }),
    addCompaniesToProject: builder.mutation({
      ...new AddCompaniesToProjectQuery(),
      invalidatesTags: [{ type: 'project', id: 'LIST' }]
    }),
    getProjectsInvitations: builder.query<IProjectInviteResponse, any>({
      ...new GetInvitationsInProjectsListQuery(),
      providesTags: ['invites-list']
    }),
    sendAnswerForInvitationInProject: builder.mutation({
      ...new SendAnswerForInvitationInProjectQuery(),
      invalidatesTags: [{ type: 'project', id: 'PARTIAL-LIST' }, { type: 'invites-list' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          arg?.onSuccessCb?.()
        } catch (e) {
          dispatch(addedNewNotification({ message: 'Не удалось выполнить запрос' }))
        }
      }
    }),
    getProjectById: builder.query({
      ...new GetProjectByIdQuery(),
      providesTags: ['project']
    }),
    deleteProjectCompany: builder.mutation({
      ...new DeleteProjectCompanyQuery(),
      invalidatesTags: ['company-project']
    }),
    putProjectById: builder.mutation({
      ...new PutProjectByIdQuey(),
      invalidatesTags: ['project']
    }),
    putAddAndRemoveUsersInProject: builder.mutation({
      ...new PutAddAndRemoveUsersInProject(),
      invalidatesTags: ['project']
    }),
    deleteProjectById: builder.mutation({
      ...new DeleteProjectByIdQuery(),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(addedNewNotification({ message: 'Проект успешно удален' }))
          arg?.cb?.()
        } catch (e) {
          dispatch(addedNewNotification({ message: 'Не удалось удалить проект' }))
        }
      }
    })
  })
})

export const {
  useLazyGetProjectCompaniesListQuery,
  useDeleteProjectByIdMutation
} = projectApi