import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetProjectUserListQuery } from './GetProjectUserListQuery'
import { GetProjectUserByIdQuery } from './GetProjectUserByIdQuery'
import { GetProjectUsersByIdsQuery } from './GetProjectUsersByIdsQuery'
import { baseQuery } from '../BaseQuery'

export const projectUserApi = createApi({
  reducerPath: 'projectUserApi',
  baseQuery: baseQuery(),
  tagTypes: ['user-project'],
  endpoints: (builder) => ({
    getList: builder.query(new GetProjectUserListQuery()),
    getById: builder.query(new GetProjectUserByIdQuery()),
    getByIds: builder.query(new GetProjectUsersByIdsQuery())
  })
})

export const {
  useGetListQuery,
  useGetByIdQuery,
  useLazyGetListQuery,
  useLazyGetByIdQuery,
  useLazyGetByIdsQuery,
} = projectUserApi