export const AMPLITUDE_EVENTS = {
  //SIGN-UP
  SIGN_UP_PAGE_OPENED: 'SIGN_UP_PAGE_OPENED',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  //LOG-IN
  LOG_IN_PAGE_OPENED: 'LOG_IN_PAGE_OPENED',
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  //LOG_OUT
  LOG_OUT_BTN_CLICK: 'LOG_OUT_BTN_CLICK',
  //LEFT_SIDEBAR_BTNS
  LEFT_SIDEBAR_BTN_OPEN_CLICK: 'LEFT_SIDEBAR_BTN_OPEN_CLICK',
  LEFT_SIDEBAR_BTN_CLOSE_CLICK: 'LEFT_SIDEBAR_BTN_CLOSE_CLICK',
  LEFT_SIDEBAR_DOCUMENTS_BUTTON_CLICK: 'LEFT_SIDEBAR_DOCUMENTS_BUTTON_CLICK',
  LEFT_SIDEBAR_DOCUMENTS_INCOMING_CLICK: 'LEFT_SIDEBAR_DOCUMENTS_INCOMING_CLICK',
  LEFT_SIDEBAR_DOCUMENTS_SENDED_CLICK: 'LEFT_SIDEBAR_DOCUMENTS_SENDED_CLICK',
  LEFT_SIDEBAR_DOCUMENTS_CONSTRUCTOR_CLICK: 'LEFT_SIDEBAR_DOCUMENTS_CONSTRUCTOR_CLICK',
  LEFT_SIDEBAR_DOCUMENTS_SEND_DOCUMENT_BTN_CLICK: 'LEFT_SIDEBAR_DOCUMENTS_SEND_DOCUMENT_CLICK',
  LEFT_SIDEBAR_CHECKPOINT_BTN_CLICK: 'LEFT_SIDEBAR_CHECKPOINT_BTN_CLICK',
  LEFT_SIDEBAR_CHECKPOINT_HISTORY_CLICK: 'LEFT_SIDEBAR_CHECKPOINT_HISTORY_CLICK',
  LEFT_SIDEBAR_CHECKPOINT_ACCESS_CLICK: 'LEFT_SIDEBAR_CHECKPOINT_ACCESS_CLICK',
  LEFT_SIDEBAR_CHAT_CLICK: 'LEFT_SIDEBAR_CHAT_CLICK',
  LEFT_SIDEBAR_CATALOG_CLICK: 'LEFT_SIDEBAR_CATALOG_CLICK',
  LEFT_SIDEBAR_CALENDAR_CLICK: 'LEFT_SIDEBAR_CALENDAR_CLICK',
  LEFT_SIDEBAR_REPORTS_CLICK: 'LEFT_SIDEBAR_REPORTS_CLICK',
  LEFT_SIDEBAR_NOTIFICATIONS_CLICK: 'LEFT_SIDEBAR_NOTIFICATIONS_CLICK',
  LEFT_SIDEBAR_ABOUT_PROJECT_CLICK: 'LEFT_SIDEBAR_ABOUT_PROJECT_CLICK',
  LEFT_SIDEBAR_CHANGE_PROJECT_CLICK: 'LEFT_SIDEBAR_CHANGE_PROJECT_CLICK',
  LEFT_SIDEBAR_CHANGE_PROJECT_SUCCESS: 'LEFT_SIDEBAR_CHANGE_PROJECT_SUCCESS',
  EDIT_PROJECT_CLICK: 'EDIT_PROJECT_CLICK',
  LEFT_SIDEBAR_MY_COMPANY_CLICK: 'LEFT_SIDEBAR_MY_COMPANY_CLICK',
  LEFT_SIDEBAR_MY_PROFILE_CLICK: 'LEFT_SIDEBAR_MY_PROFILE_CLICK',
  LEFT_SIDEBAR_CHOOSE_PROJECT_CLICK: 'LEFT_SIDEBAR_CHOOSE_PROJECT_CLICK',

  //RIGHT-SIDEBAR-BTNS
  RIGHT_SIDEBAR_OPEN_NOTIFICATIONS_BTN_CLICK: 'RIGHT_SIDEBAR_OPEN_NOTIFICATIONS_BTN_CLICK',
  RIGHT_SIDEBAR_CLOSE_NOTIFICATIONS_BTN_CLICK: 'RIGHT_SIDEBAR_CLOSE_NOTIFICATIONS_BTN_CLICK',
  RIGHT_SIDEBAR_USER_PROFILE_BTN_CLICK: 'RIGHT_SIDEBAR_USER_PROFILE_BTN_CLICK',
  RIGHT_SIDEBAR_USER_COMPANY_PROFILE_BTN_CLICK: 'RIGHT_SIDEBAR_USER_COMPANY_PROFILE_BTN_CLICK',
  RIGHT_SIDEBAR_CALENDAR_BTN_CLICK: 'RIGHT_SIDEBAR_CALENDAR_BTN_CLICK',
  RIGHT_SIDEBAR_CHAT_BTN_CLICK: 'RIGHT_SIDEBAR_CHAT_BTN_CLICK',

  //DOCUMENTS
  SUBMIT_DOCUMENTS_BTN_CLICK: 'SUBMIT_DOCUMENTS_BTN_CLICK',
  DOCUMENT_EDIT_MODE_BTN_CLICK: 'DOCUMENT_EDIT_MODE_BTN_CLICK',
  DOCUMENT_APPROVAL_STAGES_BTN_CLICK: 'DOCUMENT_APPROVAL_STAGES_BTN_CLICK',
  DOCUMENT_ATTACHMENTS_BTN_CLICK: 'DOCUMENT_ATTACHMENTS_BTN_CLICK',
  DOCUMENT_HISTORY_BTN_CLICK: 'DOCUMENT_HISTORY_BTN_CLICK',
  DOCUMENT_COMMENTS_BTN_CLICK: 'DOCUMENT_COMMENTS_BTN_CLICK',
  DOCUMENT_CONFIG_ACCESS_BTN_CLICK: 'DOCUMENT_CONFIG_ACCESS_BTN_CLICK',
  DOCUMENT_HEADER_COMPANY_LINK_CLICK: 'DOCUMENT_HEADER_COMPANY_LINK_CLICK',
  DOCUMENT_HEADER_AUTHOR_LINK_CLICK: 'DOCUMENT_HEADER_AUTHOR_LINK_CLICK',
  DOCUMENT_EDIT_SAVE_BTN_CLICK: 'DOCUMENT_EDIT_SAVE_BTN_CLICK',
  DOCUMENT_ADD_ATTACH_BTN_CLICK: 'DOCUMENT_ADD_ATTACH_BTN_CLICK',
  DOCUMENT_ADDING_ATTACH_TO_DOC_SUCCESS: 'DOCUMENT_ADDING_ATTACH_TO_DOC_SUCCESS',
  DOCUMENT_DOWNLOAD_ALL_FILES_BTN_CLICK: 'DOCUMENT_DOWNLOAD_ALL_FILES_BTN_CLICK',
  DOCUMENT_REMOVE_DOC_ATTACH_BTN_CLICK: 'DOCUMENT_REMOVE_DOC_ATTACH_BTN_CLICK',
  DOCUMENT_VIEWING_DOC_ATTACHMENT: 'DOCUMENT_VIEWING_DOC_ATTACHMENT',
  DOCUMENT_APPROVAL_STAGES_SCHEME_BTN_CLICK: 'DOCUMENT_APPROVAL_STAGES_SCHEME_BTN_CLICK',
  DOCUMENT_USER_DOC_ACCESS_CHANGED_SUCCESS: 'DOCUMENT_USER_DOC_ACCESS_CHANGED_SUCCESS',
  DOCUMENT_FOOTER_DOWNLOAD_BTN_CLICK: 'DOCUMENT_FOOTER_DOWNLOAD_BTN_CLICK',
  DOCUMENT_FOOTER_PRINT_BTN_CLICK: 'DOCUMENT_FOOTER_PRINT_BTN_CLICK',
  DOCUMENT_CHOOSE_BTN_CLICK: 'DOCUMENT_CHOOSE_BTN_CLICK',
  DOCUMENT_SEARCH_FILTER_TITLE_BTN_CLICK: 'DOCUMENT_SEARCH_FILTER_TITLE_BTN_CLICK',
  DOCUMENT_SEARCH_FILTER_FILLED_FIELDS_BTN_CLICK: 'DOCUMENT_SEARCH_FILTER_FILLED_FIELDS_BTN_CLICK',
  DOCUMENT_SEARCH_ALL_FILTERS_BTN_CLICK: 'DOCUMENT_SEARCH_ALL_FILTERS_BTN_CLICK',
  DOCUMENT_SUCCESS_SIGNMENT: 'DOCUMENT_SUCCESS_SIGNMENT',
  DOCUMENT_REJECT_SIGN: 'DOCUMENT_REJECT_SIGN',
  DOCUMENT_APPROVE_SIGN_BTN_CLICK: 'DOCUMENT_APPROVE_SIGN_BTN_CLICK',
  DOCUMENT_REJECT_SIGN_BTN_CLICK: 'DOCUMENT_REJECT_SIGN_BTN_CLICK',
  
  //TEMPLATES
  CREATE_TEMPLATE_IN_CATEGORY_BTN_CLICK: 'CREATE_TEMPLATE_IN_CATEGORY_BTN_CLICK',
  CREATE_TEMPLATE_IN_HEADER_BTN_CLICK: 'CREATE_TEMPLATE_IN_HEADER_BTN_CLICK',
  SUBMIT_DOCUMENT_IN_HEADER_BTN_CLICK: 'SUBMIT_DOCUMENT_IN_HEADER_BTN_CLICK',
  CHOOSE_TEMPLATE_IN_CONSTRUCTOR_BTN_CLICK: 'CHOOSE_TEMPLATE_IN_CONSTRUCTOR_BTN_CLICK',
  CHOOSE_TEMPLATE_IN_SUBMITING_DOCUMENT_BTN_CLICK: 'CHOOSE_TEMPLATE_IN_SUBMITING_DOCUMENT_BTN_CLICK',
  CREATE_TEMPLATES_CATEGORY_IN_CONSTRUCTOR_BTN_CLICK: 'CREATE_TEMPLATES_CATEGORY_IN_CONSTRUCTOR_BTN_CLICK',
  TEMPLATES_CATEGORY_SUCCESS_CREATION: 'TEMPLATES_CATEGORY_SUCCESS_CREATION',
  CREATE_TEMPLATE_CATEGORY_IN_CONSTRUCTOR_SELECT_BTN_CLICK: 'CREATE_TEMPLATE_CATEGORY_IN_CONSTRUCTOR_SELECT_BTN_CLICK',

  //Подача
  CLICK_ON_TEMPLATE_BTN: 'CLICK_ON_TEMPLATE_BTN',

  //Справочник
  DIRECTORY_EMPLOYEES_TAB_CLICK: 'DIRECTORY_EMPLOYEES_TAB_CLICK',
  DIRECTORY_COMPANIES_TAB_CLICK: 'DIRECTORY_COMPANIES_TAB_CLICK',
  DIRECTORY_FILTER_BY_ALPHABET: 'DIRECTORY_FILTER_BY_ALPHABET',
  DIRECTORY_EMPLOYEES_QR_DOWNLOAD_CLICK: 'DIRECTORY_EMPLOYEES_QR_DOWNLOAD_CLICK',
  DIRECTORY_COMPANIES_QR_DOWNLOAD_CLICK: 'DIRECTORY_COMPANIES_QR_DOWNLOAD_CLICK',

  //КПП
  KPP_ACCESS_OF_CAR_CREATE: 'KPP_ACCESS_OF_CAR_CREATE',
  KPP_ACCESS_OF_EMPLOYEE_CREATE: 'KPP_ACCESS_OF_EMPLOYEE_CREATE',
  KPP_ACCESS_WATCH: 'KPP_ACCESS_WATCH',

  //Журнал
  CHECKPOINT_ITEM_CAR_WATCH: 'CHECKPOINT_ITEM_CAR_WATCH',
  CHECKPOINT_TAB_CAR_CLICK: 'CHECKPOINT_TAB_CAR_CLICK',
  CHECKPOINT_TAB_EMPLOYEE_CLICK: 'CHECKPOINT_TAB_EMPLOYEE_CLICK',
  CHECKPOINT_SEARCH_HISTORY: 'CHECKPOINT_SEARCH_HISTORY',
  CHECKPOINT_DATE_CHANGE_CLICK: 'CHECKPOINT_DATE_CHANGE_CLICK',
  CHECKPOINT_LOCATION_CLICK: 'CHECKPOINT_LOCATION_CLICK',
  CHECKPOINT_LOCATION_SWITCH_CLICK: 'CHECKPOINT_LOCATION_SWITCH_CLICK',
  CHECKPOINT_LOCATION_SEARCH: 'CHECKPOINT_LOCATION_SEARCH',
  CHECKPOINT_LOCATION_ADD_BTN_CLICK: 'CHECKPOINT_LOCATION_ADD_BTN_CLICK',

  //Отчеты
  REPORTS_TYPE_SELECT: 'REPORTS_TYPE_SELECT',
  REPORTS_TEMPLATES_BTN_CLICK: 'REPORTS_TEMPLATES_BTN_CLICK',
  REPORTS_FILTERS_BTNS_CLICK: 'REPORTS_FILTERS_BTNS_CLICK',
  REPORTS_EXTEND_FILTERS_BTN_CLICK: 'REPORTS_EXTEND_FILTERS_BTN_CLICK'
} as const

export type AMPLITUDE_TYPE = typeof AMPLITUDE_EVENTS
export type AMPLITUDE_EVENTS_TYPE = AMPLITUDE_TYPE[keyof AMPLITUDE_TYPE]
