import { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useTSelector } from '../../../../(deprecated)/utils/hooks/reduxHooks'
import { getToken } from '../../../../(deprecated)/utils/localStorageManagement'
import { isEmpty } from 'lodash'

export const useAuthRedirect = () => {
  const location = useLocation()
  const { current: pathName } = useRef(location.pathname)
  const userInfo = useTSelector(state => state.user.userInfo)
  const isRedirectToAuth = !getToken() && isEmpty(userInfo) && pathName !== '/sign-up' && pathName !== '/reset-password'

  return {
    isRedirectToAuth,
    authLocationInfo: { pathname: '/auth', state: { from: pathName } }
  }
}