import { getHeaders } from '../utils/axios/getHeaders'
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'

import Host from '../utils/axios/host'

export const baseQuery = () => fetchBaseQuery({
  baseUrl: Host.getInstance().getRequestUrl(),
  prepareHeaders: async (Headers: Headers) => {
    Object.entries(await getHeaders())
      .forEach((header) => Headers.set(...header))
    return Headers
  }
})