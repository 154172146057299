import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { GetAllProjectRoles } from './GetAllProjectRoles'
import { PostCreateProjectRole } from './PostCreateProjectRole'
import { DeleteProjectRole } from './DeleteProjectRole'
import { PutEditProjectRole } from './PutEditProjectRole'
import { PutAssignUsersProjectRole } from './PutAssignUsersProjectRole'

// TODO перенести
import { addedNewNotification } from '../../../../(deprecated)/redux/notifications/actions'
import { getUserRoleInProject } from '../../../../(deprecated)/redux/user/actions'
import { baseQuery } from '../../../../(deprecated)/api/BaseQuery'


export const projectRoleApi = createApi({
  reducerPath: 'projectRoleApi',
  tagTypes: ['ProjectRole'],
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getAllProjectRoles: builder.query(new GetAllProjectRoles()),
    postCreateProjectRole: builder.mutation({
      ...new PostCreateProjectRole(),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(addedNewNotification({ message: 'Роль успешно добавлена' }))
          arg?.cb?.()
        } catch (errorData: any) {
          const message = errorData?.error?.data?.nonFieldErrors[0] ?? 'Не удалось добавить роль'
          dispatch(addedNewNotification({ message }))
        }
      }
    }),
    deleteProjectRole: builder.mutation(new DeleteProjectRole()),
    putEditProjectRole: builder.mutation({
      ...new PutEditProjectRole(),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(getUserRoleInProject({ projectId: arg?.project }))
          arg?.cb?.()
        } catch (e) {
          dispatch(addedNewNotification({ message: 'Не удалось внести изменения' }))
        }
      }
    }),
    putAssignUsersProjectRole: builder.mutation(new PutAssignUsersProjectRole())
  })
})

export const {
  useGetAllProjectRolesQuery,
  useLazyGetAllProjectRolesQuery,
  usePostCreateProjectRoleMutation,
  useDeleteProjectRoleMutation,
  usePutEditProjectRoleMutation,
  usePutAssignUsersProjectRoleMutation
} = projectRoleApi