import React, { useState } from 'react'
import { withProviders } from './providers'
import { AppRoutes } from './routing/AppRoutes'
import { AppLayout } from './layout/AppLayout'
import { useApp } from './hooks/useApp'
import { AppSnackBarProvider } from './providers/withAppSnackBar/withAppSnackBar'
import { AppLoading } from './layout/AppLoading'

import * as Sentry from '@sentry/react'

const App = () => {
  //TODO - исправить костыль,
  // на этотом стейте завязаны тенанты
  // специально вызывается перерендер
  const [hasBeenFetch, setHasBeenFetch] = useState(false)
  !hasBeenFetch && setHasBeenFetch(true)

  useApp()

  return (
    <AppLoading>
      <AppSnackBarProvider>
        <AppLayout>
          <AppRoutes/>
        </AppLayout>
      </AppSnackBarProvider>
    </AppLoading>
  )
}


export default withProviders(Sentry.withErrorBoundary(App, {}))