import React, { ButtonHTMLAttributes } from 'react'
import Loader from '../../../../(deprecated)/components/UI/Loader/Loader'
import cn from 'classnames'

import './Button.scss'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  id?: string
  isLoading?: boolean
  customClassName?: string
  buttonType?: 'secondary' | 'small' | 'only-text' | 'blue-ligth'
  icon?: React.ReactNode
  disabled?: boolean
  title?: string | React.ReactNode
  type?: 'button' | 'submit'
  onClick: (e?: React.MouseEvent) => void
  onMouseDown?: (e?: React.MouseEvent) => void
  testId?: string
}

export const Button = React.memo(React.forwardRef<HTMLButtonElement, ButtonProps>(({
  isLoading = false,
  customClassName,
  buttonType,
  icon,
  disabled,
  title,
  type = 'button',
  onClick,
  onMouseDown,
  id,
  testId,
  ...props
}, ref) => {

  const className = cn(
    'button',
    customClassName,
    {
      [`button_${buttonType}`]: buttonType,
      'button-withIcon': icon,
      'button-withIcon-without-text': icon && !title,
      'button-is-loading': isLoading
    }
  )

  return (
    <button
      type={type}
      className={className}
      onClick={isLoading ? undefined : onClick}
      disabled={disabled || isLoading}
      onMouseDown={onMouseDown}
      ref={ref}
      id={id}
      data-testid={testId}
      {...props}
    >
      {isLoading
        ? <Loader height={18} />
        : (
          <>
            {icon && <div className='button_icon'>{icon}</div>}
            {title}
          </>
        )}
    </button>
  )
}))

// Чтобы deprecated модуль мог эскопортировать
export default Button
