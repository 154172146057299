import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActionOrder, SignerBookItem } from "./model"

const initialState: ActionOrder = {
  mode: 'edit',
  isFreeOrder: false,
  signersOrder: [],
  signersBook: {},
  profileBook: {}
}

export const actionOrderSlice = createSlice({
  name: 'actionOrder',
  initialState,
  reducers: {
    changeIsActionFreeOrder(state, { payload }: PayloadAction<boolean>) {
      state.isFreeOrder = payload
    },
    setSignerOrder(state, { payload }: PayloadAction<string[]>) {
      state.signersOrder = payload
    },
    setSignerBook(state, { payload }: PayloadAction<ActionOrder['signersBook']>) {
      state.signersBook = payload
    },
    setProfileBook(state, { payload }: PayloadAction<ActionOrder['profileBook']>) {
      state.profileBook = payload
    },
    updateSignerData(state, { payload }: PayloadAction<{
      cssSelector: string,
      signer: SignerBookItem | SignerBookItem[]
    }>) {
      const { cssSelector, signer } = payload
      state.signersBook[cssSelector] = signer
    },
    setMode(state, { payload }: PayloadAction<ActionOrder['mode']>) {
      state.mode = payload
    },
    resetOrder(state) {
      Object.entries(initialState).forEach(([key, value]) => {
        const stateKey = key as keyof ActionOrder
        (state as Record<string, unknown>)[stateKey] = value
      })
    }
  }
})

export const {
  changeIsActionFreeOrder,
  setSignerOrder,
  setSignerBook,
  setProfileBook,
  updateSignerData,
  setMode,
  resetOrder
} = actionOrderSlice.actions

export default actionOrderSlice.reducer