import React, { lazy } from 'react'
import { ALL_FUNCTIONS_FOR_ACCESS } from '../../features/FunctionAccess/type/typeFunctions'

//Разделение на бандлы
const SignUpForm = lazy(() => import('../../../(deprecated)/components/pages/SignUpForm/SignUpForm'))
const LoginUser = lazy(() => import('../../../(deprecated)/components/pages/LoginUser/LoginUser'))
const ResetPassword = lazy(() => import('../../../(deprecated)/components/pages/ResetPassword/ResetPassword'))
const PageNotFound = lazy(() => import('../../../(deprecated)/components/pages/PageNotFound/PageNotFound'))

const Dashboard = lazy(() => import('../../../(deprecated)/components/pages/Dashboard/Dashboard'))
const EditCompanyPage = lazy(() => import('../../../(deprecated)/components/pages/EditCompanyPage/EditCompanyPage'))
const Catalog = lazy(() => import('../../pages/Catalog/CatalogPage'))
const Docs = lazy(() => import('../../../(deprecated)/components/pages/Docs/Docs'))
const DocumentConstructor = lazy(() => import('../../../(deprecated)/components/pages/DocumentConstructor/DocumentConstructor'))
const DocsTemplates = lazy(() => import('../../../(deprecated)/components/pages/DocsTemplates/DocsTemplates'))
const DocumentTemplate = lazy(() => import('../../../(deprecated)/components/pages/DocumentTemplate/CreateDocumentTemplate'))
const ProfilePage = lazy(() => import('../../../(deprecated)/components/pages/Profile/Profile'))
const Meetings = lazy(() => import('../../../(deprecated)/components/pages/MeetCalendar/MeetCalendar'))
const Reports = lazy(() => import('../../../(deprecated)/components/pages/Reports/Reports'))
const Chat = lazy(() => import('../../../(deprecated)/components/pages/Chat/Chat'))
const CheckpointAccess = lazy(() => import('../../../(deprecated)/components/pages/CheckpointAccess/CheckpointAccess'))
const CheckpointHistory = lazy(() => (
  import('../../../(deprecated)/components/pages/CheckpontHistory/CheckpointHistory')
))


export const routes: RouteConfigType[] = [
  {
    path: '/sign-up',
    exact: true,
    component: SignUpForm,
    accessFunctions: []
  },
  {
    path: '/auth',
    exact: true,
    component: LoginUser,
    accessFunctions: []
  },
  {
    path: '/reset-password',
    exact: true,
    component: ResetPassword,
    accessFunctions: []
  },
  {
    path: '/',
    exact: true,
    component: Dashboard,
    accessFunctions: []
  },
  {
    path: '/constructor/:id?',
    exact: true,
    render: (props: any) => <DocumentConstructor {...props} />,
    accessFunctions: ['create-template']
  },
  {
    path: '/document-template/:id',
    exact: true,
    component: DocumentTemplate,
    accessFunctions: ['create-doc']
  },
  {
    path: '/checkpoint/history',
    exact: true,
    component: CheckpointHistory,
    accessFunctions: ['read-checkpoint-logs']
  },
  {
    path: '/checkpoint/access',
    exact: true,
    render: (props: any) => <CheckpointAccess {...props} />,
    accessFunctions: ['read-checkpoint-docs']
  },
  {
    path: '/reportings',
    exact: true,
    component: Reports,
    accessFunctions: ['view-reports']
  },
  {
    path: '/documents/templates',
    exact: true,
    render: (props: any) => <DocsTemplates {...props} />,
    accessFunctions: ['create-template']
  },
  {
    path: '/documents/sending',
    exact: true,
    render: (props: any) => <DocsTemplates {...props} />,
    accessFunctions: ['create-doc']
  },
  {
    path: '/documents/:box(sent|incoming)/:id?',
    exact: true,
    render: (props: any) => <Docs {...props} />,
    accessFunctions: []
  },
  {
    path: '/profile/:mode?/:id?',
    exact: true,
    component: ProfilePage,
    accessFunctions: []
  },
  {
    path: '/company/:id',
    exact: true,
    component: EditCompanyPage,
    accessFunctions: []
  },
  {
    path: '/chat',
    exact: true,
    component: Chat,
    accessFunctions: []
  },
  {
    path: '/meetings/:id?',
    exact: true,
    component: Meetings,
    accessFunctions: []
  },
  {
    path: '/catalog',
    render: (props: any) => <Catalog {...props} />,
    accessFunctions: []
  },
  {
    path: '/not-found',
    component: PageNotFound,
    accessFunctions: []
  }
]


export type RouteConfigType = {
  path: string,
  exact?: boolean,
  component?: React.ExoticComponent | React.MemoExoticComponent<any>,
  accessFunctions: ALL_FUNCTIONS_FOR_ACCESS[],
  render?: (props: any) => React.ReactNode
}