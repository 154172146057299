import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQuery } from '../../../../(deprecated)/api/BaseQuery'
import { getProjectAttachmentsQuery } from './projectAttachmentsQueries/getProjectAttachmentsListQuery'
import { createProjectAttachmentQuery } from './projectAttachmentsQueries/createProjectAttachmentQuery'
import { deleteProjectAttachmentQuery } from './projectAttachmentsQueries/deleteProjectAttachmentQuery'

export const projectAttachmentsApi = createApi({
  reducerPath: 'projectAttachmentsApi',
  baseQuery: baseQuery(),
  tagTypes: ['project-attachments'],
  endpoints: (builder) => ({
    getProjectAttachmentsList: getProjectAttachmentsQuery(builder),
    createProjectAttachment: createProjectAttachmentQuery(builder),
    deleteProjectAttachment: deleteProjectAttachmentQuery(builder)
  })
})

export const {
  useLazyGetProjectAttachmentsListQuery,
  useCreateProjectAttachmentMutation,
  useDeleteProjectAttachmentMutation
} = projectAttachmentsApi