import { createApi } from '@reduxjs/toolkit/query/react'
import { GetCompanyListQuery } from './GetCompanyListQuery'
import { GetCompanyByIdQuery } from './GetCompanyByIdQuery'
import { PutCompanyByIdQuery } from './PutCompanyByIdQuery'
import { IInviteCompanyPayload, IInviteCompanyResponse, InviteCompanyQuery } from './InviteCompanyQuery'
import { CompanyAdapter } from './CompanyAdapter'
import { putUserCompanyInfo } from '../../redux/user/actionCreators'
import { addedNewNotification } from '../../redux/notifications/actions'
import { baseQuery } from '../BaseQuery'

export const companyApi = createApi({
  reducerPath: 'companyApi',
  tagTypes: ['company'],
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getList: builder.query(new GetCompanyListQuery()),
    getById: builder.query(new GetCompanyByIdQuery()),
    putById: builder.mutation({
      ...new PutCompanyByIdQuery(),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const deserializedCompany = new CompanyAdapter(data).getDeserializedFields()

          dispatch(putUserCompanyInfo(deserializedCompany))
          dispatch(addedNewNotification({ message: 'Изменения сохранены.' }))
        } catch (e) {
          dispatch(addedNewNotification({ message: 'Не удалось сохранить изменения.' }))
        }
      },
      invalidatesTags: ['company']
    }),
    inviteCompany: builder.mutation<IInviteCompanyResponse, IInviteCompanyPayload>({
      ...new InviteCompanyQuery()
    }),
  })
})

export const {
  useGetListQuery,
  useGetByIdQuery,
  useLazyGetListQuery,
  useLazyGetByIdQuery,
  useInviteCompanyMutation
} = companyApi
